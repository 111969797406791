import { nanoid } from 'nanoid';
import { v4 as uuidv4 } from 'uuid';
import { TFunction } from 'i18next';

import { sendMessage } from '@root/modules/ai-chat/services/send-message.service';
import { ChatHistory, Conversation, Message } from '@root/modules/ai-chat/types/chat';
import { notify } from '@root/shared/utils/notification';

import { showToast } from '../components/ui-lib';
import { DEFAULT_INPUT_TEMPLATE } from '../constant';
import { changeLikeStatus } from '../services/change-like-status.service';
import { SendMessageServiceResponse, createConversation } from '../services/create-conversation.service';
import { getConversation } from '../services/get-conversation.service';
import { prettyObject } from '../utils/format';
import { createStore } from '../utils/store';
import { estimateTokenLength } from '../utils/token';
import { ModelConfig, ModelType } from './config';
import { Mask, createEmptyMask } from './mask';

export type ChatMessage = {
  date: string;
  streaming?: boolean;
  isError?: boolean;
  id: string;
  model?: ModelType;
  message: Message | null;
  role: 'user' | 'assistant' | 'system';
};

export function createMessage(override: Partial<ChatMessage>): ChatMessage {
  return {
    id: nanoid(),
    date: new Date().toLocaleString(),
    role: 'user',
    message: null,
    ...override,
  };
}

export interface ChatStat {
  tokenCount: number;
  wordCount: number;
  charCount: number;
}

export interface ChatSession {
  id: string;
  uuid: string;
  topic: string;

  memoryPrompt: string;
  messages: ChatMessage[];
  history: ChatHistory;
  stat: ChatStat;
  lastUpdate: number;
  lastSummarizeIndex: number;
  clearContextIndex?: number;

  mask: Mask;
}
// TODO: translate
export const DEFAULT_TOPIC = 'New Conversation';

function createEmptySession(lang: string): ChatSession {
  return {
    id: nanoid(),
    uuid: uuidv4(),
    topic: '',
    memoryPrompt: '',
    messages: [],
    history: [],
    stat: {
      tokenCount: 0,
      wordCount: 0,
      charCount: 0,
    },
    lastUpdate: Date.now(),
    lastSummarizeIndex: 0,

    mask: createEmptyMask(lang),
  };
}

interface ChatStore {
  sessions: ChatSession[];
  currentSessionIndex: number;
  moveSession: (from: number, to: number) => void;
  selectSession: (index: number) => void;
  newSession: (mask?: Mask) => void;
  deleteSession: (index: number) => void;
  currentSession: () => ChatSession;
  nextSession: (delta: number) => void;
  onNewMessage: (message: ChatMessage) => void;
  onUserInput: (content: string) => Promise<void>;
  summarizeSession: () => void;
  updateStat: (message: ChatMessage) => void;
  updateCurrentSession: (updater: (session: ChatSession) => void) => void;
  updateMessage: (sessionIndex: number, messageIndex: number, updater: (message?: ChatMessage) => void) => void;
  resetSession: () => void;
  getMessagesWithMemory: () => ChatMessage[];
  getMemoryPrompt: () => ChatMessage;
  clearAllData: () => void;
}

function countMessages(msgs: ChatMessage[]) {
  return msgs.reduce((pre, cur) => pre + estimateTokenLength(cur.message?.content || ''), 0);
}

function fillTemplateWith(input: string, modelConfig: ModelConfig, lang: string) {
  const vars = {
    model: modelConfig.model,
    time: new Date().toLocaleString(),
    lang,
    input: input,
  };

  let output = modelConfig.template ?? DEFAULT_INPUT_TEMPLATE;

  // must contains {{input}}
  const inputVar = '{{input}}';
  if (!output.includes(inputVar)) {
    output += '\n' + inputVar;
  }

  Object.entries(vars).forEach(([name, value]) => {
    output = output.replaceAll(`{{${name}}}`, value);
  });

  return output;
}

const DEFAULT_CHAT_STATE = {
  sessions: [createEmptySession('en')],
  currentSessionIndex: 0,
};

export const useChatStore = createStore(DEFAULT_CHAT_STATE, (set, _get) => {
  function get() {
    return {
      ..._get(),
      ...methods,
    };
  }

  const methods = {
    selectSession(index: number) {
      set({
        currentSessionIndex: index,
      });
    },

    moveSession(from: number, to: number) {
      set((state) => {
        const { sessions, currentSessionIndex: oldIndex } = state;

        // move the session
        const newSessions = [...sessions];
        const session = newSessions[from];
        newSessions.splice(from, 1);
        newSessions.splice(to, 0, session);

        // modify current session id
        let newIndex = oldIndex === from ? to : oldIndex;
        if (oldIndex > from && oldIndex <= to) {
          newIndex -= 1;
        } else if (oldIndex < from && oldIndex >= to) {
          newIndex += 1;
        }

        return {
          currentSessionIndex: newIndex,
          sessions: newSessions,
        };
      });
    },

    newSession(lang: string, mask?: Mask) {
      const session = createEmptySession(lang);

      set((state) => {
        return {
          currentSessionIndex: 0,
          sessions: [{ ...session, topic: `Chat ${state.sessions.length + 1}` }].concat(state.sessions),
        };
      });

      return session;
    },

    nextSession(delta: number) {
      const n = get().sessions.length;
      const limit = (x: number) => (x + n) % n;
      const i = get().currentSessionIndex;
      get().selectSession(limit(i + delta));
    },

    deleteSession(index: number, t: TFunction, lang: string) {
      const deletingLastSession = get().sessions.length === 1;
      const deletedSession = get().sessions.at(index);

      if (!deletedSession) return;

      const sessions = get().sessions.slice();
      sessions.splice(index, 1);

      const currentIndex = get().currentSessionIndex;
      let nextIndex = Math.min(currentIndex - Number(index < currentIndex), sessions.length - 1);

      if (deletingLastSession) {
        nextIndex = 0;
        sessions.push(createEmptySession(lang));
      }

      // for undo delete action
      const restoreState = {
        currentSessionIndex: get().currentSessionIndex,
        sessions: get().sessions.slice(),
      };

      set(() => ({
        currentSessionIndex: nextIndex,
        sessions,
      }));

      showToast(
        t('Home.DeleteToast'),
        {
          text: t('Home.Revert'),
          onClick() {
            set(() => restoreState);
          },
        },
        5000,
      );
    },

    currentSession() {
      let index = get().currentSessionIndex;
      const sessions = get().sessions;

      if (index < 0 || index >= sessions.length) {
        index = Math.min(sessions.length - 1, Math.max(0, index));
        set(() => ({ currentSessionIndex: index }));
      }

      const session = sessions[index];

      return session;
    },

    onNewMessage(message: ChatMessage, t: TFunction) {
      get().updateCurrentSession((session) => {
        session.messages = session.messages.concat();
        session.lastUpdate = Date.now();
      });
      get().updateStat(message);
      get().summarizeSession(t);
    },

    async onMessageLike(message: Message, likeStatus: number) {
      const session = get().currentSession();

      let prevLikeStatus = 0;

      get().updateCurrentSession((session) => {
        session.messages = session.messages.map((m) => {
          if (m.message?.id === message.id) {
            prevLikeStatus = m?.message?.likeStatus;
            m.message = {
              ...m.message,
              id: m.message.id,
              likeStatus,
            };
          }

          return m;
        });
      });

      const response = await changeLikeStatus(session.uuid || '', message.id, { likeStatus });

      if (response.status !== 200) {
        notify(
          {
            type: 'danger',
            title: response.payload,
          },
          { autoClose: false },
        );

        get().updateCurrentSession((session) => {
          session.messages = session.messages.map((m) => {
            if (m.message?.id === message.id) {
              m.message = {
                ...m.message,
                id: m.message.id,
                likeStatus: prevLikeStatus,
              };
            }

            return m;
          });
        });
      }
    },

    /// Send message in current session
    async onUserInput(content: string, t: TFunction, lang: string, logEvent: (event: string, params?: any) => void) {
      const session = get().currentSession();
      const modelConfig = session.mask.modelConfig;

      const userContent = fillTemplateWith(content, modelConfig, lang);
      console.log('[User Input] after template: ', userContent);

      const userMessage: ChatMessage = createMessage({
        role: 'user',
        message: {
          content: userContent,
          createdAt: new Date().toISOString(),
          id: nanoid(),
          likeStatus: 0,
          updatedAt: new Date().toISOString(),
          userId: 'user',
          author: 'user',
          type: 'regular',
          conversationId: session.uuid || '',
          conversation: '',
        },
      });

      const botMessage: ChatMessage = createMessage({
        role: 'assistant',
        streaming: true,
        model: modelConfig.model,
      });

      // save user's and bot's message
      get().updateCurrentSession((session) => {
        const savedUserMessage = {
          ...userMessage,
          content: {
            ...userMessage.message,
            content,
          } as Message,
        };
        session.messages = session.messages.concat([savedUserMessage, botMessage]);
      });

      const showLimitError = () => {
        botMessage.message = {
          content: t('MessageDailyLimit'),
          createdAt: new Date().toISOString(),
          id: nanoid(),
          updatedAt: new Date().toISOString(),
          userId: 'user',
          author: 'bot',
          type: 'error',
          likeStatus: 0,
          conversationId: session.uuid || '',
          conversation: '',
        };
        botMessage.streaming = false;
        userMessage.isError = false;
        botMessage.isError = false;
        get().updateCurrentSession((session) => {
          session.messages = session.messages.concat();
        });
      }

      const showMessage = (conversation: Conversation, userContent: string) => {
        const messages = conversation.messages;
        const filteredMessages = messages.filter(message => message.author === 'bot');
        const message = filteredMessages.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())[filteredMessages.length - 1];

        logEvent('send_message', { message: userContent, chat_id: conversation.id });

        botMessage.streaming = false;
        if (message) {
          if (message.type === 'regular') {
            botMessage.message = message;
            get().onNewMessage(botMessage, t);
            get().updateChatHistory(message);
          } else {
            botMessage.message = message;
            botMessage.message.content = t('SomethingWentWrong');
            get().onNewMessage(botMessage, t);
            get().updateChatHistory(message);
          }
        }
      };

      let response: SendMessageServiceResponse | null = null;

      if (session.messages.length > 2) {
        console.log('### Send Message ###', userContent);
        response = await sendMessage(session.uuid, {
          message: userContent,
        });
      } else {
        console.log('### Create Conversation ###', userContent);
        response = await createConversation({
          uuid: session.uuid,
          message: userContent,
        });
      }

      // pref on finish
      if (response.status === 200) {
        showMessage(response.payload, userContent);
      }
      // make request
      else {
        const errorMessage = response.payload;

        if (errorMessage.includes('limit reached')) {
          showLimitError();
        } else if (errorMessage.includes('Network Error')) {
          let retryCount = 0;
          const MAX_RETRY_COUNT = 3;
          let succeed = false;

          if (session.uuid) {
            while (retryCount < MAX_RETRY_COUNT) {
              retryCount += 1;

              let response: SendMessageServiceResponse | null = null;

              response = await getConversation(session.uuid);

              if (response.status === 200) {
                if (session.messages?.length <= response.payload.messages?.length) {
                  succeed = true;
                  showMessage(response.payload, userContent);
                  break;
                } else {
                  await new Promise((resolve) => setTimeout(resolve, 60 * 1000));
                  continue;
                }
              }
            }
          }

          if (!succeed) {
            botMessage.message = {
              content: t('SomethingWentWrong'),
              createdAt: new Date().toISOString(),
              id: nanoid(),
              updatedAt: new Date().toISOString(),
              userId: 'user',
              author: 'bot',
              type: 'error',
              likeStatus: 0,
              conversationId: session.uuid || '',
              conversation: '',
            };
            botMessage.streaming = false;
            get().updateCurrentSession((session) => {
              session.messages = session.messages.concat();
            });
          }
        } else {
          const isAborted = errorMessage.includes('aborted');
          botMessage.message = {
            content:
              '\n\n' +
              prettyObject({
                error: true,
                message: errorMessage,
              }),
            createdAt: new Date().toISOString(),
            id: nanoid(),
            updatedAt: new Date().toISOString(),
            userId: 'user',
            author: 'bot',
            type: 'error',
            likeStatus: 0,
            conversationId: session.uuid || '',
            conversation: '',
          };
          botMessage.streaming = false;
          userMessage.isError = !isAborted;
          botMessage.isError = !isAborted;
          get().updateCurrentSession((session) => {
            session.messages = session.messages.concat();
          });
          console.error('[Chat] failed ', errorMessage);
        }
      }
    },

    getMemoryPrompt(t: TFunction) {
      const session = get().currentSession();

      return {
        role: 'system',
        message: session.memoryPrompt.length > 0 ? t('Store.Prompt.History', { content: session.memoryPrompt }) : '',
        date: '',
      } as ChatMessage;
    },

    updateMessage(sessionIndex: number, messageIndex: number, updater: (message?: ChatMessage) => void) {
      const sessions = get().sessions;
      const session = sessions.at(sessionIndex);
      const messages = session?.messages;
      updater(messages?.at(messageIndex));
      set(() => ({ sessions }));
    },

    resetSession() {
      get().updateCurrentSession((session) => {
        session.messages = [];
        session.memoryPrompt = '';
      });
    },

    summarizeSession(t: TFunction) {
      const session = get().currentSession();

      // remove error messages if any
      const messages = session.messages;

      const modelConfig = session.mask.modelConfig;
      const summarizeIndex = Math.max(session.lastSummarizeIndex, session.clearContextIndex ?? 0);
      let toBeSummarizedMsgs = messages.filter((msg) => !msg.isError).slice(summarizeIndex);

      const historyMsgLength = countMessages(toBeSummarizedMsgs);

      if (historyMsgLength > modelConfig?.max_tokens ?? 4000) {
        const n = toBeSummarizedMsgs.length;
        toBeSummarizedMsgs = toBeSummarizedMsgs.slice(Math.max(0, n - modelConfig.historyMessageCount));
      }

      // add memory prompt
      toBeSummarizedMsgs.unshift(get().getMemoryPrompt(t));

      console.log('[Chat History] ', toBeSummarizedMsgs, historyMsgLength, modelConfig.compressMessageLengthThreshold);
    },

    updateStat(message: ChatMessage) {
      get().updateCurrentSession((session) => {
        session.stat.charCount += (message.message?.content || '').length;
        // TODO: should update chat count and word count
      });
    },

    updateChatHistory(message: Message) {
      get().updateCurrentSession((session) => {
        session.history = [...session.history, message];
      });
    },

    updateCurrentSession(updater: (session: ChatSession) => void) {
      const sessions = get().sessions;
      const index = get().currentSessionIndex;
      updater(sessions[index]);
      set(() => ({ sessions }));
    },

    clearAllData() {
      localStorage.clear();
      location.reload();
    },
  };

  return methods;
});

import { Formik, FormikProps } from 'formik';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import warningImg from '@root/assets/images/cases/warning.webp';
import { TextField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { Image } from '@root/shared/ui/image';
import { Modal } from '@root/shared/ui/modal';
import { Text, Title } from '@root/shared/ui/typography';
import { notify } from '@root/shared/utils/notification';

import { getActiveMethod } from '../helpers/get-active-method';
import { ChangeEmailForm, useChangeEmailForm } from '../hooks/use-change-email-form';
import { useGetTwoFaMethods } from '../hooks/use-get-two-fa-methods';
import { sendCode } from '../services/two-fa/send-code.service';
import { authSlice } from '../store';
import { TwoFAAuthType } from '../types/two-fa';
import { BackupCodeContent } from './two-fa/backup-code-content';
import { EnterCodeContent } from './two-fa/enter-code-content';

const ChangeEmailFormComponent: FC<FormikProps<ChangeEmailForm>> = ({ values, errors, status, isSubmitting, submitForm, handleSubmit, setFieldValue }) => {
  const { t } = useTranslation('settings');
  const { data: methods } = useGetTwoFaMethods();
  const { activeMethods, email: activeMethodEmail } = getActiveMethod(methods || []);

  const dispatch = useDispatch();
  const onCancel = useCallback(() => dispatch(authSlice.actions.setChangeEmailModalOpen(false)), []);

  const handleUpdateClick = useCallback(async () => {
    if (!values.type) {
      await submitForm();
    } else {
      if (values.type === TwoFAAuthType.Email) {
        const response = await sendCode();
        if (response.status !== 200) {
          notify({
            type: 'danger',
            title: response.payload,
          });
        }
      }
      setFieldValue('step', 2);
    }
  }, [values, submitForm, setFieldValue]);

  if (status?.type === 'success') {
    return (
      <Modal isOpen footer={null} onCancel={onCancel} disabledOutSideClick>
        <div className='w-full flex flex-col items-center'>
          <Image quality={100} height={80} width={80} src={warningImg} wrapperClassName='mb-6' alt='E-Email sent illustration' />
          <Title className='text-center mb-8' level={5} bold>
            {t('changeEmailModal.requestSuccess.title')}
          </Title>
          <Text size='base' className='text-center text-gray-400 mb-10'>
            {t('changeEmailModal.requestSuccess.description')}
          </Text>
          <Button outlined onClick={onCancel}>
            {t('changeEmailModal.ok')}
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen title={t('changeEmailModal.title')} footer={null} onCancel={onCancel} disabledOutSideClick>
      <form onSubmit={handleSubmit}>
        {values.step === 1 && (
          <div className='flex flex-col justify-start gap-4'>
            <TextField name='email' label={t('changeEmailModal.email.label')} placeholder={t('changeEmailModal.email.placeholder')} />
            <div className='w-full flex justify-between items-center gap-4'>
              <Button outlined onClick={onCancel}>
                {t('changeEmailModal.cancel')}
              </Button>
              <Button loading={isSubmitting} type='button' onClick={handleUpdateClick} disabled={!!errors?.email || !values.email}>
                {t('changeEmailModal.changeEmail')}
              </Button>
            </div>
          </div>
        )}
        {values.step === 2 && (
          <div>
            {values.useBackupCode ? (
              <BackupCodeContent type={values.type} onCancel={onCancel} />
            ) : (
              <div>
                <EnterCodeContent type={values.type} email={activeMethodEmail} hasAnotherMethod={activeMethods?.length > 1} formName='ChangeEmailForm' />
                <div className='w-full flex justify-between items-center gap-4 mt-4'>
                  <Button outlined onClick={() => setFieldValue('step', 1)}>
                    {t('changeEmailModal.cancel')}
                  </Button>
                  <Button loading={isSubmitting} type='submit'>
                    {t('changeEmailModal.confirm')}
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </form>
    </Modal>
  );
};

export const ChangeEmailModal: FC = () => {
  const { initialValues, schema, onSubmit } = useChangeEmailForm();

  return <Formik initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit} component={ChangeEmailFormComponent} />;
};
